import { NgModule } from '@angular/core';
import { Routes, RouterModule,CanActivate } from '@angular/router';
import { SalePanelComponent } from './components/sales/sale-panel/sale-panel.component';
import { ProductCatalogComponent } from './components/backoffice/products/product-catalog/product-catalog.component';
import { ProductAddComponent } from './components/backoffice/products/product-add/product-add.component';
import { ProductViewComponent } from './components/backoffice/products/product-view/product-view.component';
import { ProductEditComponent } from './components/backoffice/products/product-edit/product-edit.component';
import { WareCatalogComponent } from './components/backoffice/warehouse/ware-catalog/ware-catalog.component';
import { WareAddComponent } from './components/backoffice/warehouse/ware-add/ware-add.component';
import { CashOpeningComponent } from './components/cash/cash-opening/cash-opening.component';
import { CashClosingComponent } from './components/cash/cash-closing/cash-closing.component';
import { UsersLoginComponent } from './components/backoffice/users/users-login/users-login.component';
import { UsersLogoutComponent } from './components/backoffice/users/users-logout/users-logout.component';
import { ReporteVentasCatalogComponent } from 'src/app/components/backoffice/reportes/reporte-ventas-catalog/reporte-ventas-catalog.component'
import { AuthGuardService as AuthGuard} from './auth/auth-guard.service';
import { UsersCatalogComponent } from 'src/app/components/backoffice/users/users-catalog/users-catalog.component'
import { UsersAddComponent } from 'src/app/components/backoffice/users/users-add/users-add.component'
import { UserEditComponent } from 'src/app/components/backoffice/users/user-edit/user-edit.component'
import { GastosCatalogComponent } from 'src/app/components/gastos/gastos-catalog/gastos-catalog.component'
import { GastosAddComponent } from './components/gastos/gastos-add/gastos-add.component';

const routes: Routes = [
  {
    path: 'backoffice/ventas', component: SalePanelComponent,canActivate: [AuthGuard] 
  },
  {
    path: 'login', component: UsersLoginComponent
  },
  {
    path: 'backoffice/productos', component: ProductCatalogComponent
  },
  {
    path: 'backoffice/productos/nuevo', component: ProductAddComponent
  },
  {
    path: 'backoffice/productos/:id', component: ProductViewComponent
  },
  {
    path: 'backoffice/productos/:id/modificar', component: ProductEditComponent
  },
  {
    path: 'backoffice/inventario', component: WareCatalogComponent
  },
  {
    path: 'backoffice/inventario/nueva-entrada', component: WareAddComponent
  },
  {
    path: 'backoffice/reporte/ventas', component: ReporteVentasCatalogComponent
  },
  {
    path: 'backoffice/gastos', component: GastosCatalogComponent
  },
  {
    path: 'backoffice/gastos/create', component: GastosAddComponent
  },
  {
    path: 'users', component: UsersCatalogComponent
  },
  {
    path: 'users/Edit/:id', component: UserEditComponent
  },
  {
    path: 'users/Nuevo', component: UsersAddComponent
  },
  {
    path: 'caja/apertura', component: CashOpeningComponent,canActivate: [AuthGuard] 
  },
  {
    path: 'caja/cerrar', component: CashClosingComponent,canActivate: [AuthGuard] 
  },
  {
    path: 'cerrar', component: UsersLogoutComponent
  },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
