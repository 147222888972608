import { Component, OnInit } from '@angular/core';
import {formatDate,registerLocaleData } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX';
import { CashService } from 'src/app/services/cash.service'
import { Router } from '@angular/router';
registerLocaleData(localeEsMx, 'es-MX');

@Component({
  selector: 'app-cash-closing',
  templateUrl: './cash-closing.component.html',
  styleUrls: ['./cash-closing.component.styl']
})
export class CashClosingComponent implements OnInit {

  public FechaActual: Date = new Date();
  public FormatoFecha = '';
  public caja:any;
  public total:any;

  constructor(private cashService:CashService,private router: Router) {
    setInterval(() => {
      this.FechaActual = new Date();2
      this.FormatoFecha = formatDate(this.FechaActual, "dd 'de' MMMM 'del' yyyy hh:mm:ss a",'es-MX');
    }, 1);
  }

  ngOnInit() {
    this.cashService.findCash(localStorage.getItem('cash')).subscribe(response=>{
      this.caja = response.data;
    });

    this.cashService.salesCash(localStorage.getItem('cash')).subscribe(response=>{
      this.total = response.data;
    });
  }

  Guardar(){
    this.cashService.updateCash(localStorage.getItem('cash'),this.total,this.FechaActual).subscribe(response=>{
      if(response.status == 200){
        this.router.navigate(["cerrar"]);
      }
    });
  }

}
