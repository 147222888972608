import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from 'src/app/models/UserModel';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.styl']
})
export class UserFormComponent implements OnInit {
  @Output() sendForm : EventEmitter<any> = new EventEmitter();

  @Input() control : EventEmitter<any>;

  @Input()  user : User;
  
  @Input() action : string;

  userForm : FormGroup;

  constructor(
    public snack: MatSnackBar) { }

  ngOnInit() {

    this.initFormGroup();
    
    // set current values
    if(this.control){
      this.control.subscribe( c => {
        this.userForm.get("id").setValue(this.user.id)
        this.userForm.get("username").setValue(this.user.username)
        this.userForm.get("nombre").setValue(this.user.nombre)
        this.userForm.get("password").setValue(this.user.password)
        this.userForm.get("rol").setValue(this.user.rol)
      })
    }
    
  }

  initFormGroup() {
    this.userForm = new FormGroup({
      id : new FormControl(this.user.id),
      username: new FormControl(this.user.username,[
        Validators.required
      ]),
      nombre: new FormControl(this.user.nombre,[
        Validators.required
      ]),
      password: new FormControl(this.user.password,[
        Validators.required
      ]),
      rol: new FormControl(this.user.rol,[
        Validators.required
      ]),
    });
  }

  // Al recibir el submit valido enviar el evento al padre
  onSubmit(isValid){
    if (!isValid) return;

    // Parse data from the form
    this.user.id = this.userForm.get("id").value
    this.user.username = this.userForm.get("username").value
    this.user.nombre = this.userForm.get("nombre").value
    this.user.password = this.userForm.get("password").value
    this.user.rol = this.userForm.get("rol").value

    // Send new product data
    this.sendForm.emit(this.user);

  }

}
