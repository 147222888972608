import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CATALOGS } from '../mocks/mock-catalog';
import { Observable, of } from 'rxjs';
import { IHTTPResponse } from '../interfaces/IHttpResponse';
import { CATALOGS_ENDPOINT } from '../config/endpoints';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {

  constructor(private http: HttpClient) { }

  getTestCatalogs(): Observable<IHTTPResponse>{
    return of({
      status: 200,
      data: {
        catalogs: CATALOGS
      },
      errors: null
    });
  }

  getCatalogs(): Observable<IHTTPResponse>{
    return this.http.get<IHTTPResponse>(CATALOGS_ENDPOINT);
  }

}
