import { Injectable } from '@angular/core';
import { PRODUCTS } from '../mocks/mock-products';
import { Observable, of } from 'rxjs';
import { Product } from '../models/ProductModel';
import { IHTTPResponse } from '../interfaces/IHttpResponse';
import { HttpClient } from '@angular/common/http';
import { PRODUCTS_ENDPOINT } from '../config/endpoints';


@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  getTestProducts(): Observable<IHTTPResponse>{
    return of({
      status: 200,
      data: {
        products: PRODUCTS
      },
      errors: null
    });
  }

  getProducts(): Observable<IHTTPResponse>{
    return this.http.get<IHTTPResponse>(PRODUCTS_ENDPOINT);
  }
  
  getProductByBarcode(barcode : string): Observable<IHTTPResponse>{
    return this.http.get<IHTTPResponse>(PRODUCTS_ENDPOINT+"/search-product/"+barcode);
  }
  
  isOnStock(id : number, qty : number): Observable<IHTTPResponse>{
    return this.http.get<IHTTPResponse>(PRODUCTS_ENDPOINT+'/'+id+'/check-available/'+qty);
  }

  createProduct(bodyData): Observable<IHTTPResponse>{
    return this.http.post<IHTTPResponse>(PRODUCTS_ENDPOINT,bodyData);
  }

  getProduct(id): Observable<IHTTPResponse>{
    return this.http.get<IHTTPResponse>(PRODUCTS_ENDPOINT+"/"+id);
  }

  deleteProduct(id): Observable<IHTTPResponse>{
    return this.http.delete<IHTTPResponse>(PRODUCTS_ENDPOINT+"/"+id);
  }

  updateProduct(id,bodyData): Observable<IHTTPResponse>{
    return this.http.put<IHTTPResponse>(PRODUCTS_ENDPOINT+"/"+id,bodyData);
  }

}
