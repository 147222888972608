import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-users-logout',
  templateUrl: './users-logout.component.html',
  styleUrls: ['./users-logout.component.styl']
})
export class UsersLogoutComponent implements OnInit {

  constructor(private loginService:LoginService,
    private router:Router) { }

  ngOnInit() {
    this.loginService.logout();

    this.router.navigate(["/login"]);
  }

}
