import { Component, OnInit, EventEmitter } from '@angular/core';
import { Product } from 'src/app/models/ProductModel';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { PRODUCTS } from 'src/app/mocks/mock-products';
import { ProductsService } from 'src/app/services/products.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.styl',
  '../../../../app.component.styl'
]
})
export class ProductEditComponent implements OnInit {

  editProduct : Product = new Product();
  FControl : EventEmitter<any> = new EventEmitter();
  busy : boolean = false;

  constructor( public snack: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private productservice: ProductsService) { }

  ngOnInit() {

    this.route.paramMap.pipe(switchMap((params: ParamMap) => this.productservice.getProduct(params.get('id')) )).subscribe( response => {
      if(!response.errors){
      
        this.editProduct.id = response.data.product.id;
        this.editProduct.nombre = response.data.product.nombre;
        this.editProduct.barcode = response.data.product.barcode;
        this.editProduct.CatalogId = response.data.product.CatalogId;
        this.editProduct.precio_venta = response.data.product.precio_venta;
        this.editProduct.precio_compra = response.data.product.precio_compra;
        this.editProduct.precio_noche = response.data.product.precio_noche;
        this.editProduct.max_almacen = response.data.product.max_almacen;
        this.editProduct.min_almacen = response.data.product.min_almacen;

        this.FControl.emit("");

      }else{
        if(response.errors[0].productId){
          this.snack.open("Error "+response.errors[0].productId,"Ok")
        }
      }
    } )
    
  }

  updateProduct(){
    if(this.busy){
      return;
    }

    this.busy = true;
    this.productservice.updateProduct(this.editProduct.id,this.editProduct).subscribe( response => {
      if(!response.errors){
        
        this.snack.open("Información actualizada","Ok",{duration: 2300})
        this.router.navigate(["backoffice/productos"])
      }else{
        this.snack.open("No se pudo actualizar el producto","Ok",)
      }
      this.busy = false;
    },error => {
      this.busy = false;
    }
    )
    
  }

}
