import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Product } from 'src/app/models/ProductModel';
import { Catalog } from 'src/app/models/CatalogModel';
import { CatalogsService } from 'src/app/services/catalogs.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.styl',
  '../../../../app.component.styl'
]
})

export class ProductFormComponent implements OnInit {

  @Output() sendForm : EventEmitter<any> = new EventEmitter();

  @Input() control : EventEmitter<any>;

  @Input()  product : Product;
  
  @Input() action : string;
  
  @Input() catalogs : Catalog[];

  productForm : FormGroup;

  constructor(
    private catalogservice: CatalogsService,
    public snack: MatSnackBar) { }

  ngOnInit() {

    this.initFormGroup();
    
    // set current values
    if(this.control){
      this.control.subscribe( c => {
        this.productForm.get("id").setValue(this.product.id)
        this.productForm.get("nombre").setValue(this.product.nombre)
        this.productForm.get("barcode").setValue(this.product.barcode)
        //this.productForm.get("CatalogId").setValue(this.product.CatalogId)
        this.productForm.get("precio_venta").setValue(this.product.precio_venta)
        this.productForm.get("precio_compra").setValue(this.product.precio_compra)
        this.productForm.get("precio_noche").setValue(this.product.precio_noche)
        this.productForm.get("max_almacen").setValue(this.product.max_almacen)
        this.productForm.get("min_almacen").setValue(this.product.min_almacen)
        this.productForm.get("iva").setValue(this.product.iva)
      })
    }

    // Fetch catalogs
    /*this.catalogservice.getCatalogs().subscribe( response => {
      if(!response.errors){
        this.catalogs = response.data.catalogs
      }else{
        this.snack.open("Hubo un problema de conexion con la  base de datos","Ok")
      }
    } )*/
    
  }

  initFormGroup() {
    this.productForm = new FormGroup({
      id: new FormControl(this.product.id, [
      ]),
      nombre: new FormControl(this.product.nombre, [
        Validators.required
      ]),
      barcode: new FormControl(this.product.barcode, [
      ]),
      /*CatalogId: new FormControl(this.product.CatalogId, [
        Validators.required
      ]),*/
      precio_venta: new FormControl(this.product.precio_venta, [
        Validators.required,
        Validators.min(0)
      ]),
      precio_compra: new FormControl(this.product.precio_compra, [
        Validators.required,
        Validators.min(0)
      ]),
      precio_noche: new FormControl(this.product.precio_noche, [
        Validators.required,
        Validators.min(0)
      ]),
      max_almacen: new FormControl(this.product.max_almacen, [
        Validators.required,
        Validators.min(1)
      ]),
      min_almacen: new FormControl(this.product.min_almacen, [
        Validators.required,
        Validators.min(0)
      ]),
      iva: new FormControl(this.product.iva, [
      ])
    })
  }

  // Al recibir el submit valido enviar el evento al padre
  onSubmit(isValid){
    if (!isValid) return;

    // Parse data from the form
    this.product.id = this.productForm.get("id").value
    this.product.nombre = this.productForm.get("nombre").value
    this.product.barcode = this.productForm.get("barcode").value
    //this.product.CatalogId = this.productForm.get("CatalogId").value
    this.product.precio_venta = this.productForm.get("precio_venta").value
    this.product.precio_compra = this.productForm.get("precio_compra").value
    this.product.precio_noche = this.productForm.get("precio_noche").value
    this.product.max_almacen = this.productForm.get("max_almacen").value
    this.product.min_almacen = this.productForm.get("min_almacen").value

    
    // PENDING FEATURE
    // if(this.productForm.get("iva")){
      // this.product.precio_venta = this.product.precio_venta *1.16  
    // }

    // Send new product data
    this.sendForm.emit(this.product);

  }
  
}
