import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/models/ProductModel';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { User } from 'src/app/models/UserModel';

@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html',
  styleUrls: ['./users-add.component.styl']
})
export class UsersAddComponent implements OnInit {

  newUser : User;
  
  constructor(
    public snack: MatSnackBar,
    private router: Router,
    private userService: UsersService
    ) {  }

  ngOnInit() {
    this.newUser = new Product();
  }

  createNewUser(){
  
     this.userService.createUser(this.newUser).subscribe( response => {

      if(!response.errors){
        this.snack.open("Usuario agregado correctamente.","Ok",{duration: 2300})
        this.router.navigate(["/users"])
      }else{
        this.snack.open("Error "+response.errors[0],"Ok")
      }
     })

  }

}
