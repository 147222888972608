import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { CashService } from 'src/app/services/cash.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router,private cashService:CashService) {}
  
  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }else{
      this.cashService.CheckCash(localStorage.getItem('user_id')).subscribe(response=>{
        if(response.data.open){
          localStorage.setItem('cash', response.data.cash.id);
          return true;
        }else{
          this.router.navigate(['caja/apertura']);
          return true;
        }
      });
    }
    return true;
  }
}
