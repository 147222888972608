export const BASE_URL = "http://xtremewatersportsmazatlan.dwggl.com:3000/api/v1"
//export const BASE_URL = "localbackend/api/v1"

export const PRODUCTS_ENDPOINT = BASE_URL+"/products"
export const CATALOGS_ENDPOINT = BASE_URL+"/catalogs"
export const SALES_ENDPOINT = BASE_URL+"/sales"
export const SALESINDEX_ENDPOINT = BASE_URL+"/salesIndex"
export const WAREHOUSEENTRY_ENDPOINT = BASE_URL+"/warehouse-entry"
export const WAREHOUSEENTRYFILTER_ENDPOINT = BASE_URL+"/warehouse-entry-get"

export const USERS_ENDPOINT = BASE_URL+"/users"
export const LOGIN_ENDPOINT = BASE_URL+"/users/login"
export const CASHOPENING_ENDPOINT = BASE_URL+"/cash/check"
export const CASHOPEN_ENDPOINT = BASE_URL+"/cash/open"
export const CASHFIND_ENDPOINT = BASE_URL+"/cash/find"
export const CASHSALES_ENDPOINT = BASE_URL+"/cash/sales"
export const CASHUPDATES_ENDPOINT = BASE_URL+"/cash/update"
export const USERDELETE_ENDPOINT = BASE_URL+"/users/delete"

export const GASTOSCREATE_ENDPOINT = BASE_URL+"/gasto/create"
export const GASTOSFILTER_ENDPOINT = BASE_URL+"/gasto/filter"
export const GASTOSDELETE_ENDPOINT = BASE_URL+"/gasto/delete"

export const USERFIND_ENDPOINT = BASE_URL+"/users/find"
export const USERUPDATE_ENDPOINT = BASE_URL+"/users/update"