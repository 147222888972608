import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/models/ProductModel';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DeleteDialog } from '../../../util/dialogs/delete.dialog.component';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import { PRODUCTS } from 'src/app/mocks/mock-products';
import { switchMap } from 'rxjs/operators';
import { IHTTPResponse } from 'src/app/interfaces/IHttpResponse';
import { ProductsService } from 'src/app/services/products.service';
import { Builders } from 'src/app/builders/builders';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.styl',
  '../../../../app.component.styl'
]
})
export class ProductViewComponent implements OnInit {

  showProduct : Product = new Product();

  constructor(
    public dialog: MatDialog,
    public snack: MatSnackBar,
    private route: ActivatedRoute,
    private productservice: ProductsService) { }

  ngOnInit() {

    // Obtener el parametro de  la  url (query param)
    // para buscar la informacion  del producto de la base  de  datos.
    this.route.paramMap.pipe(switchMap((params: ParamMap) => this.productservice.getProduct(params.get('id')) )).subscribe( (response : IHTTPResponse) => {

      if(!response.errors){

        // Build from json
        this.showProduct = Builders.buildProduct(response.data.product)
        
      }else{
        if(response.errors[0].productId){
          this.snack.open("Error "+response.errors[0].productId,"Ok")
        }
      }
    })


  }

}
