import { Component, OnInit,ViewChild } from '@angular/core';
import { PageEvent, MatTable, MatSnackBar } from '@angular/material';
import { Gastos } from 'src/app/models/GastosModel';
import { GastosService } from 'src/app/services/gastos.service';
import { Builders } from 'src/app/builders/builders';
import { Alert } from 'selenium-webdriver';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as jspdf from 'jspdf';  
import html2canvas from 'html2canvas'; 

@Component({
  selector: 'app-gastos-catalog',
  templateUrl: './gastos-catalog.component.html',
  styleUrls: ['./gastos-catalog.component.styl']
})
export class GastosCatalogComponent implements OnInit {

  source : Gastos[] = [];
  columns: string[] = ['id', 'nombre', 'precio', 'fecha','eliminar'];
  public FechaIni:Date = new Date();
  public FechaF: Date = new Date();

  length = 100;
  pageSize = 12;
  pageSizeOptions: number[] = [12, 24, 48, 60];

  // MatPaginator Output
  pageEvent: PageEvent;

  changePaginator(ev){
    console.log(ev)
  }

  constructor(private gastosService : GastosService,
    private snack : MatSnackBar) { }

  ngOnInit() {
    this.fetchList();
  }

  fetchList(){
    this.gastosService.indexGastos(this.FechaIni,this.FechaF).subscribe(response=>{
      if(!response.errors){
        // Map information
        this.length = response.data.gastos.length;
        this.source = response.data.gastos.map( ( (salesEntriesJson) => {
          return Builders.buildGastosEntry(salesEntriesJson);
        }))

        // Actualizar la  tabla
        this.table.renderRows();
      }else{
        this.snack.open("No se pudo recuperar las ventas realizadas.")
      }
    });
  }

  deleteUsuario(id){
    this.gastosService.DeleteGasto(id).subscribe(response=>{
      this.fetchList();
    });
  }

  Consultar(){
    this.fetchList();
  }

  @ViewChild(MatTable,{static: true}) table: MatTable<any>;

}
