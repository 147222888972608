import { Component, OnInit,ViewChild } from '@angular/core';
import { PageEvent, MatTable, MatSnackBar } from '@angular/material';
import { SaleList } from 'src/app/models/SalesListModel';
import { SalesService } from 'src/app/services/sales.service';
import { UsersService } from 'src/app/services/users.service'
import { Builders } from 'src/app/builders/builders';
import { Alert } from 'selenium-webdriver';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as jspdf from 'jspdf';  
import html2canvas from 'html2canvas'; 

@Component({
  selector: 'app-reporte-ventas-catalog',
  templateUrl: './reporte-ventas-catalog.component.html',
  styleUrls: ['./reporte-ventas-catalog.component.styl']
})
export class ReporteVentasCatalogComponent implements OnInit {

  source : SaleList[] = [];
  columns: string[] = ['id', 'productos', 'total', 'username','createdAt'];
  public FechaIni:Date = new Date();
  public FechaF: Date = new Date();
  public Actual: Date = new Date();
  public total:any = 0;
  public Usuarios: any = [];
  public Usuario:any = 0;

  // MatPaginator Inputs
  length = 100;
  pageSize = 12;
  pageSizeOptions: number[] = [12, 24, 48, 60];

  // MatPaginator Output
  pageEvent: PageEvent;

  changePaginator(ev){
    console.log(ev)
  }

  constructor(private salesService : SalesService,
    private userService : UsersService,
    private snack : MatSnackBar) { }

  ngOnInit() {
    this.Usuario = "0";
    this.fetchList();
    this.fetchUser();
  }

  fetchUser(){
    this.userService.getUsers().subscribe(response=>{
      this.Usuarios = response.data.users;
    });
  }

  Consultar(){
    this.fetchList();
  }

  toPDF(){
      var data = document.getElementById('contentToConvert');  
      html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      /*
          Here are the numbers (paper width and height) that I found to work. 
          It still creates a little overlap part between the pages, but good enough for me.
          if you can find an official number from jsPDF, use them.
          */
         var imgData = canvas.toDataURL('image/png');

          var imgWidth = 210; 
          var pageHeight = 295;  
          var imgHeight = canvas.height * imgWidth / canvas.width;
          var heightLeft = imgHeight;

          var doc = new jspdf('p', 'mm');
          var position = 0;

          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          doc.save('Reporte.pdf');
    }); 
  }

  fetchList(){
    this.total=0;
    this.salesService.getSales(this.FechaIni,this.FechaF,this.Usuario).subscribe(response=>{
      if(!response.errors){
        // Map information
        this.length = response.data.sales.length;
        this.source = response.data.sales.map( ( (salesEntriesJson) => {
          this.total+=parseFloat(salesEntriesJson.total);
          return Builders.buildSalesEntry(salesEntriesJson);
        }))

        // Actualizar la  tabla
        this.table.renderRows();
      }else{
        this.snack.open("No se pudo recuperar las ventas realizadas.")
      }
    });
  }

  @ViewChild(MatTable,{static: true}) table: MatTable<any>;

}
