import {User} from './UserModel' 
export class SaleList{

    constructor(
        public id?:number,
        public productos?:number,
        public total?:string,
        public cash_id?:number,
        public createdAt?:string,
        public updatedAt?:string,
        public User?: User
        ) {
    }

}