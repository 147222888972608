import { Component, OnInit, ViewChild } from '@angular/core';
import { Product } from 'src/app/models/ProductModel';
import { PageEvent, MatTable, MatSnackBar } from '@angular/material';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { WarehouseEntry } from 'src/app/models/WarehouseEntryModel';
import { Gastos } from 'src/app/models/GastosModel';
import { Builders } from 'src/app/builders/builders';
import { GastosService } from 'src/app/services/gastos.service';

@Component({
  selector: 'app-ware-catalog',
  templateUrl: './ware-catalog.component.html',
  styleUrls: ['./ware-catalog.component.styl']
})
export class WareCatalogComponent implements OnInit {

  public FechaIni:Date = new Date();
  public FechaF: Date = new Date();
  public gastos : Gastos[] = [];
  source : WarehouseEntry[] = [];
  columns: string[] = ['id', 'nombre', 'fecha', 'tipo','cantidad','precio'];

  // MatPaginator Inputs
  length = 100;
  pageSize = 12;
  pageSizeOptions: number[] = [12, 24, 48, 60];
  Egresos:number = 0;
  Ingresos:number = 0;
  Utilidad:number = 0;


  // MatPaginator Output
  pageEvent: PageEvent;

  changePaginator(ev){
    console.log(ev)
  }

  constructor(private warehouseservice : WarehouseService,
    private gastosService : GastosService,
    private snack : MatSnackBar) { }

  ngOnInit() {
    this.fetchStock();
    this.getGastos();
  }

  fetchStock(){
    
    
    this.warehouseservice.getStock(this.FechaIni,this.FechaF).subscribe( result => {
      if(!result.errors){
        this.length = result.data.warehouseEntries.length
        // Map information
        this.source = result.data.warehouseEntries.map( ( (wareHouseEntriesJson) => {
          if(wareHouseEntriesJson.tipo == "ENTRADA"){
            this.Egresos += parseFloat(wareHouseEntriesJson.precio);
          }else{
            this.Ingresos += parseFloat(wareHouseEntriesJson.precio);
          }
          return Builders.buildWarehouseEntry(wareHouseEntriesJson);
        }))

        this.Utilidad = this.Ingresos - this.Egresos;
        // Actualizar la  tabla
        this.table.renderRows();
      }else{
        this.snack.open("No se pudo recuperar la información del inventario")
      }
    } )

  }

  getGastos(){
    this.gastosService.indexGastos(this.FechaIni,this.FechaF).subscribe(response=>{
      if(!response.errors){
        // Map information
        this.length = response.data.gastos.length;
        this.gastos = response.data.gastos.map( ( (salesEntriesJson) => {
          this.Egresos += parseFloat(salesEntriesJson.precio);
          return Builders.buildGastosEntry(salesEntriesJson);
        }))

        // Actualizar la  tabla
        this.Utilidad = this.Ingresos - this.Egresos;
      }else{
        this.snack.open("No se pudo recuperar las ventas realizadas.")
      }
    });
  }

  Consultar(){
    this.Egresos = 0;
    this.Ingresos = 0;
    this.Utilidad = 0;
    this.fetchStock();
    this.getGastos();
  }

  @ViewChild(MatTable,{static: true}) table: MatTable<any>;


}
