import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IHTTPResponse } from '../interfaces/IHttpResponse';
import { CASHOPENING_ENDPOINT,CASHOPEN_ENDPOINT,CASHFIND_ENDPOINT,CASHSALES_ENDPOINT, CASHUPDATES_ENDPOINT } from '../config/endpoints';
import { monthsShort } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CashService {

  constructor(private http: HttpClient) { }

  CheckCash(idUsuario): Observable<IHTTPResponse>{
    return this.http.post<IHTTPResponse>(CASHOPENING_ENDPOINT,{idUsuario:idUsuario});
  }

  cashOpen(cash): Observable<IHTTPResponse>{
    return this.http.post<IHTTPResponse>(CASHOPEN_ENDPOINT,cash);
  }

  findCash(ids):Observable<IHTTPResponse>{
    return this.http.post<IHTTPResponse>(CASHFIND_ENDPOINT,{id:ids});
  }

  salesCash(ids):Observable<IHTTPResponse>{
    return this.http.post<IHTTPResponse>(CASHSALES_ENDPOINT,{cash_id:ids});
  }

  updateCash(ids,monto,fecha):Observable<IHTTPResponse>{
    return this.http.post<IHTTPResponse>(CASHUPDATES_ENDPOINT,{id:ids,monto:monto,fecha_salida:fecha});
  }
}
