import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Gastos } from 'src/app/models/GastosModel';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-gastos-form',
  templateUrl: './gastos-form.component.html',
  styleUrls: ['./gastos-form.component.styl']
})
export class GastosFormComponent implements OnInit {
  @Output() sendForm : EventEmitter<any> = new EventEmitter();

  @Input() control : EventEmitter<any>;

  @Input()  gastos : Gastos;
  
  @Input() action : string;

  gastosForm : FormGroup;

  constructor(
    public snack: MatSnackBar) { }

  ngOnInit() {

    this.initFormGroup();
    
  }

  initFormGroup() {
    this.gastosForm = new FormGroup({
      descripcion: new FormControl(this.gastos.nombre,[
        Validators.required
      ]),
      precio: new FormControl(this.gastos.precio,[
        Validators.required
      ]),
      fecha: new FormControl(this.gastos.fecha,[
        Validators.required
      ])
    });
  }

  // Al recibir el submit valido enviar el evento al padre
  onSubmit(isValid){
    if (!isValid) return;

    // Parse data from the form
    this.gastos.nombre = this.gastosForm.get("descripcion").value
    this.gastos.precio = this.gastosForm.get("precio").value
    this.gastos.fecha = this.gastosForm.get("fecha").value

    // Send new product data
    this.sendForm.emit(this.gastos);

  }

}
