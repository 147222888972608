import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IProductSaleItem } from 'src/app/interfaces/IProductSaleItem';

@Component({
  selector: 'app-products-bill',
  templateUrl: './products-bill.component.html',
  styleUrls: ['./products-bill.component.styl']
})
export class ProductsBillComponent implements OnInit {

  @Input()
  products : IProductSaleItem[];

  @Output()
  onPurchase : EventEmitter<any> = new EventEmitter();

  vtotal : number = 0;

  get iva(){
    return ((this.total*16)/116);
  }

  get subtotal(){
    return (this.total-this.iva);
  }

  get total(){
    let values = this.products.map((el)=> {return el.total});
    let total = 0;
    total = values.reduce((a,b)=>{
      return a+b
    },0)
    this.vtotal = total;
    return total;

  }

  constructor() { }

  ngOnInit() {
  }

  cobrar(){
    this.onPurchase.emit(this.vtotal);
  }

}
