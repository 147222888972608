import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IHTTPResponse } from '../interfaces/IHttpResponse';
import * as moment from 'moment';
import { USERS_ENDPOINT,USERDELETE_ENDPOINT,USERFIND_ENDPOINT,USERUPDATE_ENDPOINT } from '../config/endpoints';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http:HttpClient) { }

  isLoggedIn() {
    return moment().isBefore(this.getExpiration()); 
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  getUsers(){
    return this.http.get<IHTTPResponse>(USERS_ENDPOINT);
  }

  deleteUser(id){
    return this.http.post<IHTTPResponse>(USERDELETE_ENDPOINT,{id:id});
  }

  createUser(user){
    return this.http.post<IHTTPResponse>(USERS_ENDPOINT,user);
  }

  findUser(id){
    return this.http.post<IHTTPResponse>(USERFIND_ENDPOINT,{id:id});
  }

  updateUser(bodyData){
    return this.http.put<IHTTPResponse>(USERUPDATE_ENDPOINT,bodyData);
  }
}
