import { Component, OnInit, EventEmitter } from '@angular/core';
import { User } from 'src/app/models/UserModel';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { PRODUCTS } from 'src/app/mocks/mock-products';
import { UsersService } from 'src/app/services/users.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.styl']
})
export class UserEditComponent implements OnInit {
  editUser : User = new User();
  FControl : EventEmitter<any> = new EventEmitter();

  constructor( public snack: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService) { }

  ngOnInit() {

    this.route.paramMap.pipe(switchMap((params: ParamMap) => this.usersService.findUser(params.get('id')) )).subscribe( response => {
      if(!response.errors){
      
        this.editUser.id = response.data.users.id;
        this.editUser.nombre = response.data.users.nombre;
        //this.editUser.password = response.data.users.password;
        this.editUser.rol = response.data.users.rol;
        this.editUser.username = response.data.users.username;

        this.FControl.emit("");

      }else{
        if(response.errors[0].productId){
          this.snack.open("Error "+response.errors[0].productId,"Ok")
        }
      }
    } )
    
  }

  updateUser(){

    this.usersService.updateUser(this.editUser).subscribe( response => {
      if(!response.errors){
        this.snack.open("Información actualizada","Ok",{duration: 2300})
        this.router.navigate(["/users"])
      }else{
        this.snack.open("No se pudo actualizar el usuario","Ok",)
      }
    }
    )
    
  }

}
