import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/models/ProductModel';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.styl',
  '../../../../app.component.styl'
]
})

export class ProductAddComponent implements OnInit {

  newProduct : Product;
  busy : boolean = false;
  
  constructor(
    public snack: MatSnackBar,
    private router: Router,
    private productservice: ProductsService
    ) {  }

  ngOnInit() {
    this.newProduct = new Product();
  }

  createNewProduct(){

    if(this.busy){
      return;
    }

    this.busy = true;
  
     this.productservice.createProduct(this.newProduct).subscribe( response => {

      if(!response.errors){
        this.snack.open("Producto agregado al inventario","Ok",{duration: 2300})
        this.router.navigate(["/backoffice/productos"])
      }else{
        this.snack.open("Error "+response.errors[0],"Ok")
      }
      this.busy = false;
     },error =>{
      this.busy = false;
     })

  }

}










