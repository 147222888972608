export class IProductSaleItem {
    

    constructor(public id?: number,
        public barcode?: string,
        public nombre?: string,
        public cantidad?: number,
        public precio?: number,
        public ProductId?: number){

    }

    get total(){
        return this.cantidad * this.precio
    }
}
