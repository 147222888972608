import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { noPositiveValidator } from 'src/app/validators/no-positive.validator';

@Component({
    selector: 'app-delete-dialog',
    templateUrl: 'delete.dialog.component.html',
    styleUrls: ['delete.dialog.component.styl']
  })

  export class DeleteDialog {

    title : string;
    content : string;
  
    constructor(
      public dialogRef: MatDialogRef<DeleteDialog>,
      @Inject(MAT_DIALOG_DATA) public data) {
        this.title = data.title;
        this.content = data.content;
    }

    closeModal(): void {
      this.dialogRef.close(false);
    }

  }
  