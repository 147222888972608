export class Product{

    constructor(
        public id?:number,  
        public nombre?: string,
        public barcode?: string,
        public CatalogId?: number,
        public precio_venta?: number,
        public precio_compra?: number,
        public precio_noche?: number,
        public max_almacen?: number,
        public min_almacen?: number,
        public stock?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public deleted?: boolean,
        public iva: boolean=true)
        {
    }

}