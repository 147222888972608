import { Component, OnInit, Input, Output, ViewChild, ElementRef, } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Product } from './../../../models/ProductModel'

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { EventEmitter } from '@angular/core';
import { PRODUCTS } from 'src/app/mocks/mock-products';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-products-search',
  templateUrl: './products-search.component.html',
  styleUrls: ['./products-search.component.styl']
})
export class ProductsSearchComponent implements OnInit {

  @Input()
  parentBus:  EventEmitter<any>;

  @Output()
  addProduct: EventEmitter<any> = new EventEmitter();

  FControl = new FormControl('');
  filteredProducts : Observable<Product[]>
  products : Product[] = []

  selectedProduct : Product;

  constructor(private productsservice: ProductsService) {
    
  }

  ngOnInit() {

    // Esperar codigo de barras en el input
    // O nombre del producto
    this.FControl.valueChanges.subscribe( value => {

      this.productsservice.getProductByBarcode(value).subscribe( (result) => {
        if(!result.errors){
          let productos = []
          productos.push(result.data.product)
          this.products = productos;
        }
      })

    })


    // Filtrar las opciones recuperadas  de la base de datos
    this.filteredProducts = this.FControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    
    // Recover product Object
    this.filteredProducts.subscribe( data => {
      if(data.length == 1){
        this.selectedProduct = data[0]
      }
    })
    
    if(this.parentBus){
      this.parentBus.subscribe( (data) => {
        if(data=="focus-search"){
          this.focusSearch();
        }
        if(data=="clear-form"){
          this.clearForm();
        }
      })
    }

  }

  // Filtrado de productos
  private _filter(value: string = ""): Product[] {
    const filterValue = value.toLowerCase();
    return this.products.filter( product => ( product.nombre.toLowerCase().includes(filterValue) || product.barcode == filterValue));
  }

  public fetchMockProducts(){
    return PRODUCTS;
  }

  public clearForm(){
    this.FControl.setValue("")
  }

  public sendProduct(){
    this.addProduct.emit(this.selectedProduct)
  }

  @ViewChild("search",{static: true}) nameField: ElementRef;
  focusSearch():void{
    this.nameField.nativeElement.focus();
  }
  
}
