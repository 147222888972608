export class Cash{

    constructor(
        public id?: number,
        public fecha_entrada?:Date,
        public fecha_salida?:Date,
        public monto_entrada?: number,
        public monto_salida?:number,
        public usuario_id?:number){
            
        }

}