export class User{

    constructor(
        public id?:number,
        public username?:string,
        public nombre?:string,
        public password?:string,
        public rol?:string,
        public createdAt?:string,
        public updatedAt?:string
        )
        {
    }

}