import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MomentModule } from 'ngx-moment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from "@auth0/angular-jwt";
import { AuthGuardService as AuthGuard} from './auth/auth-guard.service';
// MATERIAL IMPORT
import {MatNativeDateModule,MatSnackBarModule,MatIconModule,MatDialogModule, MatButtonModule, MatTableModule, MatPaginatorModule , MatSortModule,MatTabsModule, MatCheckboxModule, MatToolbarModule, MatCard, MatCardModule, MatFormField, MatFormFieldModule, MatProgressSpinnerModule, MatInputModule } from  '@angular/material';
import {MatDatepickerModule} from  '@angular/material/datepicker';
import {MatRadioModule} from  '@angular/material/radio';
import {MatSelectModule} from  '@angular/material/select';
import {MatSliderModule} from  '@angular/material/slider';
import {MatDividerModule} from  '@angular/material/divider';
import {MatAutocompleteModule} from  '@angular/material/autocomplete';
import {MatListModule} from  '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { TokenInterceptor } from './interceptors/token-interceptor';

// VIEW IMPORTS
import { ProductsListComponent } from './components/sales/products-list/products-list.component';
import { ProductsSearchComponent } from './components/sales/products-search/products-search.component';
import { ProductsBillComponent } from './components/sales/products-bill/products-bill.component';
import { SalePanelComponent } from './components/sales/sale-panel/sale-panel.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Add2saleComponent } from './components/sales/add2sale/add2sale.component';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { DialogSaleOverview } from './components/sales/sale-panel/dialog-sale-overview';
import { ProductCatalogComponent } from './components/backoffice/products/product-catalog/product-catalog.component';
import { ProductAddComponent } from './components/backoffice/products/product-add/product-add.component';
import { ProductFormComponent } from './components/backoffice/products/product-form/product-form.component';
import { ProductEditComponent } from './components/backoffice/products/product-edit/product-edit.component';
import { ProductViewComponent } from './components/backoffice/products/product-view/product-view.component';
import { DeleteDialog } from './components/util/dialogs/delete.dialog.component';

// Angular imports
import { HttpClientModule } from '@angular/common/http';
import { WareCatalogComponent } from './components/backoffice/warehouse/ware-catalog/ware-catalog.component';
import { WareAddComponent } from './components/backoffice/warehouse/ware-add/ware-add.component';
import { UsersAddComponent } from './components/backoffice/users/users-add/users-add.component';
import { UsersCatalogComponent } from './components/backoffice/users/users-catalog/users-catalog.component';
import { UsersLoginComponent } from './components/backoffice/users/users-login/users-login.component';
import { CashOpeningComponent } from './components/cash/cash-opening/cash-opening.component';
import { CashClosingComponent } from './components/cash/cash-closing/cash-closing.component';
import { UsersFormLoginComponent } from './components/backoffice/users/users-form-login/users-form-login.component';
import { UsersLogoutComponent } from './components/backoffice/users/users-logout/users-logout.component';
import { ReporteVentasCatalogComponent } from './components/backoffice/reportes/reporte-ventas-catalog/reporte-ventas-catalog.component';
import { UserFormComponent } from './components/backoffice/users/user-form/user-form.component';
import { UserEditComponent } from './components/backoffice/users/user-edit/user-edit.component';
import { GastosCatalogComponent } from './components/gastos/gastos-catalog/gastos-catalog.component';
import { GastosFormComponent } from './components/gastos/gastos-form/gastos-form.component';
import { GastosAddComponent } from './components/gastos/gastos-add/gastos-add.component';

@NgModule({
  declarations: [
    AppComponent,
    ProductsListComponent,
    ProductsSearchComponent,
    ProductsBillComponent,
    SalePanelComponent,
    Add2saleComponent,
    DialogSaleOverview,
    ProductCatalogComponent,
    ProductAddComponent,
    ProductFormComponent,
    ProductEditComponent,
    ProductViewComponent,
    DeleteDialog,
    WareCatalogComponent,
    WareAddComponent,
    UsersAddComponent,
    UsersCatalogComponent,
    UsersLoginComponent,
    CashOpeningComponent,
    CashClosingComponent,
    UsersFormLoginComponent,
    UsersLogoutComponent,
    ReporteVentasCatalogComponent,
    UserFormComponent,
    UserEditComponent,
    GastosCatalogComponent,
    GastosFormComponent,
    GastosAddComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,MatDividerModule,MatSliderModule,MatSelectModule,MatRadioModule,MatNativeDateModule,MatDatepickerModule,MatSnackBarModule,MatIconModule,MatDialogModule,MatProgressSpinnerModule,MatButtonModule,MatSortModule,MatTableModule,MatTabsModule, MatCheckboxModule, MatToolbarModule, MatCardModule, MatFormFieldModule, MatProgressSpinnerModule, MatInputModule, MatPaginatorModule,MatAutocompleteModule,MatListModule,MatMenuModule,
    MatSlideToggleModule,
    HttpClientModule,
    MomentModule,
    JwtModule.forRoot({
      config: {
        // ...
        tokenGetter: () => {
          return localStorage.getItem("token");
        },
        whitelistedDomains: ['localhost:4200','xtremewatersportsmazatlan.dwggl.com','xtremewatersportsmazatlan.dwggl.com:3000','localhost:3000','localbackend','localhost:4200/localbackend/api/v1/users/login','/localbackend/api/v1/users/login','xtremewatersportsmazatlan.dwggl.com:3000/api/v1/users/login']
      }
    })
  ],
  exports:[
    MatTabsModule,MatDividerModule,MatSliderModule,MatSelectModule,MatRadioModule,MatNativeDateModule,MatDatepickerModule,MatSnackBarModule,MatIconModule,MatDialogModule,MatProgressSpinnerModule,MatButtonModule,MatSortModule, MatCheckboxModule, MatToolbarModule, MatCardModule,MatTableModule,MatTabsModule, MatFormFieldModule, MatProgressSpinnerModule, MatInputModule, MatPaginatorModule,MatAutocompleteModule,MatListModule,MatMenuModule,
    MatSlideToggleModule
  ],
  entryComponents: [
    DialogSaleOverview,
    DeleteDialog
  ],
  providers: [
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
