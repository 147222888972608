import { Component, OnInit , Output, EventEmitter, Input} from '@angular/core';
import { Login } from 'src/app/models/LoginModel';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-users-form-login',
  templateUrl: './users-form-login.component.html',
  styleUrls: ['./users-form-login.component.styl',
  '../../../../app.component.styl']
})
export class UsersFormLoginComponent implements OnInit {

  @Output() sendForm : EventEmitter<any> = new EventEmitter();

  @Input() control : EventEmitter<any>;
  
  @Input() action : string;

  @Input() login : Login;

  public loginForm : FormGroup;

  constructor(public snack: MatSnackBar) { }

  ngOnInit() {
    this.initFormGroup();

    if(this.control){
      this.control.subscribe( c => {
        this.loginForm.get("Usuario").setValue(this.login.Usuario)
        this.loginForm.get("Password").setValue(this.login.Password)
      })
    }
  }

  initFormGroup() {
    this.loginForm = new FormGroup({
      Usuario: new FormControl(this.login.Usuario, [
        Validators.required
      ]),
      Password: new FormControl(this.login.Password, [
        Validators.required
      ]),
    })
  }

  onSubmit(isValid){
    if (!isValid) return;

    this.login.Usuario = this.loginForm.get("Usuario").value
    this.login.Password = this.loginForm.get("Password").value

    this.sendForm.emit(this.login
  );
  }
}
