import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Product } from 'src/app/models/ProductModel';
import { Observable } from 'rxjs';
import { IProductSaleItem } from './../../../interfaces/IProductSaleItem'
import { ProductsService } from 'src/app/services/products.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add2sale',
  templateUrl: './add2sale.component.html',
  styleUrls: ['./add2sale.component.styl']
})
export class Add2saleComponent implements OnInit {

  @Output()
  add2sale: EventEmitter<IProductSaleItem> = new EventEmitter();

  @Input()
  parentBus:  EventEmitter<any>;

  @Output()
  delete: EventEmitter<IProductSaleItem> = new EventEmitter();

  hora:Date = new Date()
  

  saleForm = new FormControl(1,[
    Validators.required,
    Validators.min(1)
  ]);
  product2Add : Product;
  productsale : IProductSaleItem;

  constructor(
    private productservice : ProductsService,
    public snack: MatSnackBar,
    public router: Router) {
  }

  
  ngOnInit() {
    this.saleForm.setValue(1);

    this.parentBus.subscribe(d => {
      if(d  == "clear-form"){
        this.clearForm();
      }
    })
  }
  
  public reciveSelectedProduct(producto : Product){
    this.product2Add = producto;
    this.editQty();
  }

  public saleProduct(){

    if(this.product2Add == null ){
      this.snack.open("Producto inválido, por favor revise la  información")
    }

    // Recuperar ide de producto y cantidad que se quiere
    let id = this.product2Add.id;
    let qty = this.saleForm.value;

    // Si la cantidad es valida
    if(this.saleForm.valid){
      

      // Revisar la existencia suficiente en el almacen
      this.productservice.isOnStock(id,qty).subscribe( result => {
          
          // Si hay cantidad suficiente 
          if(!result.errors){
            var weekday = new Array(7);
            weekday[0] = "Sunday";
            weekday[1] = "Monday";
            weekday[2] = "Tuesday";
            weekday[3] = "Wednesday";
            weekday[4] = "Thursday";
            weekday[5] = "Friday";
            weekday[6] = "Saturday";

            var n = weekday[this.hora.getDay()];
            alert(this.hora.getHours());
            if(n=="Sunday" && this.hora.getHours() >= 17 || this.hora.getHours() <= 8){
              this.add2sale.emit(new IProductSaleItem(null,this.product2Add.barcode,this.product2Add.nombre,this.saleForm.value,this.product2Add.precio_noche,this.product2Add.id));
            }else if(n!="Sunday" && this.hora.getHours() >= 23 || this.hora.getHours() <= 8){
              this.add2sale.emit(new IProductSaleItem(null,this.product2Add.barcode,this.product2Add.nombre,this.saleForm.value,this.product2Add.precio_noche,this.product2Add.id));
            }else{
              this.add2sale.emit(new IProductSaleItem(null,this.product2Add.barcode,this.product2Add.nombre,this.saleForm.value,this.product2Add.precio_venta,this.product2Add.id));
            }

          }else{

            // Si hay cantidad insuficiente
            if(result.errors[0].hasOwnProperty("qty_insuficient")){
              // Mostrar al usuario si  desea registrar nueva entrada
              this.snack.open("Solo cuenta con "+result.data.product.stock,"ok", {duration: 5000})
              /*sn.onAction().subscribe( () => {
                window.open(`backoffice/inventario/nueva-entrada?productId=${this.product2Add.id}`)
              })*/
            }
          }
      })

    }
  }

  public clearForm(){
    this.saleForm.setValue(1)
    this.product2Add = null;
  }

  @ViewChild("qty",{static: true}) nameField: ElementRef;
  editQty():void{
    this.nameField.nativeElement.focus();
    this.nameField.nativeElement.select();
  }

}
