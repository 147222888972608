import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { noPositiveValidator } from 'src/app/validators/no-positive.validator';
@Component({
    selector: 'app-sale-dialog',
    templateUrl: 'sale-dialog.component.html',
    styleUrls: ['./dialog-sale.component.styl']
  })
  export class DialogSaleOverview {

    vpagado  : number = 0;
    vcambio : number = 0;
    total  : number;

    formCtl : FormGroup = new FormGroup({
      pagado: new FormControl({value: this.vpagado},[
        Validators.required
      ]),
      total: new FormControl({
        value: this.total, 
        disabled: true
      },[]),
      cambio: new FormControl({value: this.vcambio},[
        //noPositiveValidator()
      ]),
    })
  
    constructor(
      public dialogRef: MatDialogRef<DialogSaleOverview>,
      @Inject(MAT_DIALOG_DATA) public data) {
        
        this.total = data.total;
        this.formCtl.get('total').setValue(data.total);

        this.formCtl.get('pagado').setValue("");
        this.formCtl.get('cambio').setValue("");

        this.formCtl.get("pagado").valueChanges.subscribe((t)=>{
          this.formCtl.get('cambio').setValue((t-this.total).toFixed(2));
          //this.formCtl.get('cambio').markAsDirty();
        })

      }
  
    closeModal(): void {
      //this.dialogRef.close(this.formCtl.valid);
    }
    onSubmit(): void {
      this.dialogRef.close();
    }

    hasError(controlName : string, errorName  : string){
      return this.formCtl.controls[controlName].hasError(errorName);
    }
  
  }
  