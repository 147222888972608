import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Product } from 'src/app/models/ProductModel';
import { MatSnackBar } from '@angular/material';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { WarehouseEntry } from 'src/app/models/WarehouseEntryModel';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { Builders } from 'src/app/builders/builders';


@Component({
  selector: 'app-ware-add',
  templateUrl: './ware-add.component.html',
  styleUrls: ['./ware-add.component.styl']
})
export class WareAddComponent implements OnInit {

  product : Product = new Product();

  parentBus : EventEmitter<any> = new EventEmitter();
  busy : boolean = false;

  unidades : FormControl = new FormControl(1,[
    Validators.required,
    Validators.min(1)
  ])

  constructor(
    private snack : MatSnackBar,
    private warehouse : WarehouseService,
    private router : Router,
    private route: ActivatedRoute,
    private productservice: ProductsService
  ) { }

  ngOnInit() {

    //recover query param
    this.route.queryParams.subscribe( params => {

      if(params["productId"]){
        this.productservice.getProduct(params["productId"]).subscribe( result => {
          this.product = Builders.buildProduct(result.data.product);
        })
      }

    })


  }

  reciveSelectedProduct(selectedProduct){
    this.product = selectedProduct;
  }

  saveEntry(){
    if(this.product.id == null){
      this.snack.open("Debe buscar primero un producto por medio del código de barras","Ok")
    }else{
      if(!this.unidades.valid){
        this.snack.open("Llene de manera correcta todos los campos","Ok")
      }else{

        let whe = new WarehouseEntry(
          null,
          this.product.id,
          WarehouseEntry.IN_ENTRY,
          this.unidades.value,
          this.product.precio_compra * this.unidades.value)

          if(this.busy){
            return;
          }
          this.busy = true
        

        this.warehouse.updateStock(whe).subscribe( result => { 
          if(!result.errors){
            this.snack.open("Entrada registrada","Ok");
            this.router.navigate(["backoffice/inventario"])
          }else{
            this.snack.open("No pudo registrarse la entrada de mercancía","Ok")
          }
          this.busy = false;
        },error =>{
          this.busy = false;
        })

      }
    }

  }

}
