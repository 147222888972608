import { IHTTPResponse } from "../interfaces/IHttpResponse";
import { Product } from '../models/ProductModel';
import { WarehouseEntry } from '../models/WarehouseEntryModel';
import { SaleList } from '../models/SalesListModel';
import { User } from '../models/UserModel';
import { Gastos } from '../models/GastosModel';

export module Builders { 

    export function buildCatalog(catalogJson){
        return catalogJson
    }

    export function buildProduct(productJson) {

        // Validate relationship
        let catalogo = productJson.hasOwnProperty("Catalog")  ?  buildCatalog(productJson.Catalog.nombre) : null

        return new Product(productJson.id,
            productJson.nombre,
            productJson.barcode,
            productJson.CatalogId,
            productJson.precio_venta,
            productJson.precio_compra,
            productJson.precio_noche,
            productJson.max_almacen,
            productJson.min_almacen,
            productJson.stock,
            catalogo,
            productJson.createdAt,
            productJson.updatedAt,
            productJson.deleted)
    }

    export function buildUser(userJson) {
        return new User(
            userJson.id,
            userJson.username,
            userJson.nombre,
            "",
            userJson.rol,
            "",
            ""
        );
    }

    export function buildWarehouseEntry(warehouseEntryJson){

        // Validate relationship
        let product = warehouseEntryJson.hasOwnProperty("Product")  ?  this.buildProduct(warehouseEntryJson.Product) : null

        return new WarehouseEntry(warehouseEntryJson.id,
            warehouseEntryJson.ProductId,
            warehouseEntryJson.tipo,
            warehouseEntryJson.cantidad,
            warehouseEntryJson.precio,
            warehouseEntryJson.createdAt,
            product)
    }

    export function buildSalesEntry(salesEntryJson){
        let user = salesEntryJson.hasOwnProperty("User")  ?  this.buildUser(salesEntryJson.User) : null
         return new SaleList(
            salesEntryJson.id,
            salesEntryJson.productos,
            salesEntryJson.total,
            salesEntryJson.cash_id,
            salesEntryJson.createdAt,
            "",
            user);
    }

    export function buildGastosEntry(gastosEntryJson){
        
         return new Gastos(
             gastosEntryJson.id,
             gastosEntryJson.nombre,
             gastosEntryJson.precio,
             gastosEntryJson.fecha
         );
    }
}