import { Component, OnInit,ViewChild } from '@angular/core';
import { UsersService } from 'src/app/services/users.service'
import { PageEvent, MatTable, MatSnackBar } from '@angular/material';
import { User } from 'src/app/models/UserModel'
import { Builders } from 'src/app/builders/builders';

@Component({
  selector: 'app-users-catalog',
  templateUrl: './users-catalog.component.html',
  styleUrls: ['./users-catalog.component.styl']
})
export class UsersCatalogComponent implements OnInit {

  source : User[] = [];
  columns: string[] = ['id', 'username', 'nombre', 'rol','editar','eliminar'];

  length = 100;
  pageSize = 12;
  pageSizeOptions: number[] = [12, 24, 48, 60];

  pageEvent: PageEvent;

  changePaginator(ev){
    console.log(ev)
  }

  constructor(private userService:UsersService,
    private snack : MatSnackBar) { }

  

  ngOnInit() {
    this.fetchUser();
  }

  fetchUser(){
    this.userService.getUsers().subscribe( result => {
      if(!result.errors){

        // Map information
        this.length = result.data.users.length;
        this.source = result.data.users.map( ( (usersEntriesJson) => {
          return Builders.buildUser(usersEntriesJson);
        }))

        // Actualizar la  tabla
        this.table.renderRows();
      }else{
        this.snack.open("No se pudo recuperar la información de los usuarios.")
      }
    } )
  }
  
  deleteUsuario(id){
    this.userService.deleteUser(id).subscribe(response=>{
      if(response.status == 200){
        this.snack.open("Usuario eliminado.","Ok",{duration: 2300})
        this.fetchUser();
      }else{
        this.snack.open("No se pudo eliminar el Usuario.","Ok",{duration: 2300})
      }
      
    });
  }

  @ViewChild(MatTable,{static: true}) table: MatTable<any>;

}
