import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IHTTPResponse } from '../interfaces/IHttpResponse';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GASTOSCREATE_ENDPOINT,GASTOSDELETE_ENDPOINT,GASTOSFILTER_ENDPOINT } from '../config/endpoints';

@Injectable({
  providedIn: 'root'
})
export class GastosService {

  constructor(private http:HttpClient) { }

  CreateGasto(bodyData) {
    return this.http.post<IHTTPResponse>(GASTOSCREATE_ENDPOINT,bodyData);
  }

  indexGastos(FechaInicio,FechaFin) {
    return this.http.post<IHTTPResponse>(GASTOSFILTER_ENDPOINT,{FechaInicio:FechaInicio,FechaFin:FechaFin});
  }

  DeleteGasto(id) {
    return this.http.post<IHTTPResponse>(GASTOSDELETE_ENDPOINT,{id:id});
  }
}
