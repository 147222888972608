import { Component, OnInit } from '@angular/core';
import {formatDate,registerLocaleData } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX';
import { MatSnackBar } from '@angular/material';
import { empty } from 'rxjs';
import { Cash } from 'src/app/models/CashModel'
import { CashService } from 'src/app/services/cash.service'
import { Router } from '@angular/router';
registerLocaleData(localeEsMx, 'es-MX');

@Component({
  selector: 'app-cash-opening',
  templateUrl: './cash-opening.component.html',
  styleUrls: ['./cash-opening.component.styl']
})
export class CashOpeningComponent implements OnInit {
  public FechaActual: Date = new Date();
  public FormatoFecha = '';
  public monto:any;
  public cash:Cash;

  constructor(public snack: MatSnackBar,
    private router: Router,
    private cashService:CashService) {
    setInterval(() => {
      this.FechaActual = new Date();
      this.FormatoFecha = formatDate(this.FechaActual, "dd 'de' MMMM 'del' yyyy hh:mm:ss a",'es-MX');
    }, 1);
  }

  ngOnInit() {
  }

  Guardar(){
    if(this.monto == null){ this.snack.open("El monto no puede estar vacio.","Ok",{duration: 2300}); return; }

    this.cash = new Cash();
    this.cash.fecha_entrada = this.FechaActual;
    this.cash.monto_entrada = this.monto;
    this.cash.usuario_id = parseInt(localStorage.getItem('user_id'));

    this.cashService.cashOpen(this.cash).subscribe(response=>{
      if(response.status == 200){
        this.router.navigate(["/backoffice/productos"]);
      }else{
        this.snack.open("No se pudo abrir la caja.","Ok",{duration: 2300});
      }
    });
  }

}
