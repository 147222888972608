import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IHTTPResponse } from '../interfaces/IHttpResponse';
import { WAREHOUSEENTRY_ENDPOINT,WAREHOUSEENTRYFILTER_ENDPOINT } from '../config/endpoints';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(private  http: HttpClient) { }

  updateStock(bodyData): Observable<IHTTPResponse>{
    return this.http.post<IHTTPResponse>(WAREHOUSEENTRY_ENDPOINT,bodyData);
  }

  getStock(FechaInicio,FechaFin): Observable<IHTTPResponse>{
    return this.http.post<IHTTPResponse>(WAREHOUSEENTRYFILTER_ENDPOINT,{FechaInicio:FechaInicio,FechaFin:FechaFin});
  }


}
