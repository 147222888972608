import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Login } from 'src/app/models/LoginModel';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-users-login',
  templateUrl: './users-login.component.html',
  styleUrls: ['./users-login.component.styl']
})
export class UsersLoginComponent implements OnInit {
  toLogin : Login;
  constructor(
    public snack: MatSnackBar,
    private router: Router,
    private loginService:LoginService) { }

  ngOnInit() {
    this.toLogin = new Login();
  }

  toLoginDo(){
    this.loginService.login(this.toLogin).subscribe(response=>{
      if(this.loginService.valid(response)){
        this.loginService.setSession(response);
        if(response.data.user.rol == 'admin'){
          this.router.navigate(["/backoffice/productos"]);
        }else{
          this.router.navigate(["/backoffice/ventas"]);
        }
      }else{
        this.snack.open("Usuario y/o Contraseña Incorrecta.","Ok",{duration: 2300})
      }
    });
  }

}
