import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { Product } from 'src/app/models/ProductModel';
import { PageEvent, MatDialog, MatSnackBar, MatTable } from '@angular/material';
import { format } from 'util';
import { from } from 'rxjs';
import { Router } from '@angular/router';

import { DeleteDialog } from 'src/app/components/util/dialogs/delete.dialog.component';
import { ProductsService } from 'src/app/services/products.service'

import { Builders } from 'src/app/builders/builders'
@Component({
  selector: 'app-product-catalog',
  templateUrl: './product-catalog.component.html',
  styleUrls: [
    './product-catalog.component.styl',
    '../../../../app.component.styl'
  ]
})
export class ProductCatalogComponent implements OnInit {

  source : Product[] = [];
  columns: string[] = ['id', 'nombre', 'precio_venta','precio_noche','disponibles','max_almacen','min_almacen','options'];

   // MatPaginator Inputs
   length = 100;
   pageSize = 12;
   pageSizeOptions: number[] = [12, 24, 48, 60];
 
   // MatPaginator Output
   pageEvent: PageEvent;

   public rol:any = localStorage.getItem('rol');

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public snack: MatSnackBar,
    public productservice: ProductsService
    ) { }

  ngOnInit() {
    this.fetchProducts();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  changePaginator(ev){
    console.log(ev)
  }

  deleteProduct(id){

    const dialogRef = this.dialog.open(DeleteDialog, {
      width: '300px',
      autoFocus: false,
      data: {
        title: "Eliminar producto",
        content: "¿Está seguro que desea eliminar este producto?"
      }
    });

    dialogRef.afterClosed().subscribe( modalResponse => {
      if(modalResponse){
        this.productservice.deleteProduct(id).subscribe( response => {
          if(!response.errors){
            this.snack.open("Producto eliminado del inventario","Ok",{duration: 2300})
            this.fetchProducts();
          }
        })

      }

    })

  }

  fetchProducts(){

    // TODO SEND PAGINATION
    this.productservice.getProducts().subscribe( response => {
      if(!response.errors){

        this.length = response.data.products.length;
        // Map information
        this.source = response.data.products.map((productJson) => {
          return Builders.buildProduct(productJson);
        });

        // Refresh table
        this.table.renderRows();
        
        // Set pagination data
        // this.length = this.source.length;
        // this.pageSize = 12;

      }else{
        this.snack.open("Hubo un problema de conexion con la  base de datos","Ok")
      }
    })

  }
  
  @ViewChild(MatTable,{static: true}) table: MatTable<any>;

}
