import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IHTTPResponse } from '../interfaces/IHttpResponse';
import { HttpClient } from '@angular/common/http';
import { SALES_ENDPOINT,SALESINDEX_ENDPOINT } from '../config/endpoints';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(private http: HttpClient) { 
  }

  getSales(FechaIni,FechaF,User_ID): Observable<IHTTPResponse>{
    return this.http.post<IHTTPResponse>(SALESINDEX_ENDPOINT,{FechaInicio:FechaIni,FechaFin:FechaF,user:User_ID});
  }

  createSale(bodyData): Observable<IHTTPResponse>{
    return this.http.post<IHTTPResponse>(SALES_ENDPOINT,{
      sales_array: bodyData
    });
  }

}
