export class Gastos{

    constructor(
        public id?: number,
        public nombre?:String,
        public precio?:number,
        public fecha?:Date
        ){
        }

}