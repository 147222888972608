import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IHTTPResponse } from '../interfaces/IHttpResponse';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LOGIN_ENDPOINT } from '../config/endpoints';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http:HttpClient) { }

  login(bodyData) {
    return this.http.post<IHTTPResponse>(LOGIN_ENDPOINT,bodyData);
  }
  

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('rol');
    localStorage.removeItem('expires_at');
  }

  valid(authResult){
    if(authResult.status == 200){
      return true;
    }else{
      return false;
    }
  }

  setSession(authResult) {
    const expiresAt = moment().add(authResult.data.token, 'hour');
    localStorage.setItem('token', authResult.data.token);
    localStorage.setItem('user_id', authResult.data.user.id);
    localStorage.setItem('rol', authResult.data.user.rol);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  }

  // Se encripta a base64 en el formato basic authentication
  buildHeaders(user, pass) {
    const base64data = btoa(`${user}:${pass}`);
    alert(base64data);
    return {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Authorization: `Basic ${base64data}`
        }
      )
    };

  }

  getToken() {
    return localStorage.getItem('token');
  }

  getRole() {
    return localStorage.getItem('role');
  }

  currentUser() {
    return localStorage.getItem('username');
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (err: any) => {
      return of({ token: err });
    };
  }

  

}
