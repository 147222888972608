import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormArray } from '@angular/forms';

import { Product } from './../../../models/ProductModel'

import { of, from } from 'rxjs';
import {MatTable} from '@angular/material';

import { EventEmitter } from '@angular/core';
import { IProductSaleItem } from 'src/app/interfaces/IProductSaleItem';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.styl']
})
export class ProductsListComponent implements OnInit {

  @Input()
  products : IProductSaleItem[]

  @Input()
  listen : EventEmitter<any>

  @Input()
  deleteProduct : EventEmitter<any>

  displayedColumns: string[] = ['barcode',"name","qty","price","total","eliminar"];

  constructor() {
  }

  ngOnInit() {

    if(this.listen){
      this.listen.subscribe( () => {
        this.refresh();
      })
    }
  }

  @ViewChild(MatTable,{static: true}) table: MatTable<any>;

  delete(i){
    //this.products.splice(i,1);
    //this.table.renderRows();
    this.deleteProduct.emit(this.products[i]);
  }

  refresh(): void {
    this.table.renderRows();
  }

}