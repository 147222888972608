import { Component, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl']
})
export class AppComponent {
  title = 'La Tiendita';
  public rol:any = localStorage.getItem('rol');

  ngDoCheck(){
    this.rol = localStorage.getItem('rol');
  } 

  @ViewChild(MatMenuTrigger,{static: true}) trigger: MatMenuTrigger;

  
}
