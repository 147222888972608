import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef,Inject } from '@angular/core';
import { Observable, from } from 'rxjs';
import { IProductSaleItem } from 'src/app/interfaces/IProductSaleItem';
import { MatDialog, MatDialogConfig, MatDialogRef, MatSnackBar, throwMatDuplicatedDrawerError } from '@angular/material';
import { DialogSaleOverview } from './dialog-sale-overview';
import { SalesService } from 'src/app/services/sales.service';
import { ProductsService } from 'src/app/services/products.service';
import { Product } from 'src/app/models/ProductModel';

@Component({
  selector: 'app-sale-panel',
  templateUrl: './sale-panel.component.html',
  styleUrls: ['./sale-panel.component.styl']
})
export class SalePanelComponent implements OnInit {
  

  @Output()
  salesBus:  EventEmitter<any> = new EventEmitter();
  
  @Output()
  listen : EventEmitter<any> = new EventEmitter();

  @Output()
  deleteEvent: EventEmitter<IProductSaleItem> = new EventEmitter();

  actions = [];

  saleProducts : IProductSaleItem[] = [];
  saleProductsCodes : string[] = [];
 
  constructor(
    public dialog: MatDialog,
    public snack: MatSnackBar,
    public saleservice: SalesService,
    public productservice: ProductsService
  ){
  }

  ngOnInit() {
    this.deleteEvent.subscribe(response=>{
      let index = this.saleProductsCodes.indexOf(response.ProductId.toString());
      this.saleProductsCodes.splice(index,1)
      this.saleProducts.splice(index,1);
      this.listen.emit("refresh");
    });
  }

  public add2sale(product : IProductSaleItem){
    // Si el producto ya se marco
    let ix = this.saleProductsCodes.indexOf(product.ProductId.toString());
    if(ix >= 0){
      // Calcular la  nueva  cantidad del producto
      let ncantidad = this.saleProducts[ix].cantidad + product.cantidad;

      // Revisar en el almacen si existe la cantidad suficiente
      // del producto
      
      this.productservice.isOnStock(product.ProductId,ncantidad).subscribe( (result) => { 

        //  Si no hay errores, significa que hay
        // la cantidad suficiente
        if(!result.errors){
          // Actualizar la cantidad del producto marcado
          this.saleProducts[ix].cantidad = ncantidad;
          
          return;
        }

        // Si el producto no se encontro
        if(result.errors[0].hasOwnProperty("productId")){
          this.snack.open(result.errors[0].productId,null, {duration: 5000})
          return;
        }

        // Si contiene el error qty_insuficient
        // significa que hay  faltante en el almacen.
        if(result.errors[0].hasOwnProperty("qty_insuficient")){

          // Notificar al usuario que debe realizar un
          // registro
          this.snack.open("Solo cuenta con "+result.data.product.stock,"ok", {duration: 5000})

        }
        
      })
    }else{
      
      // Agregar el producto  a la lista
      this.saleProducts.push(product);

      // Registrar codigo de barras para control de repetidos
      this.saleProductsCodes.push(product.ProductId.toString());

      // Limpiar forms, actualizar tabla
      this.listen.emit("refresh");
      this.tableContainer.nativeElement.scrollTop = this.tableContainer.nativeElement.scrollHeight;
      this.salesBus.emit("focus-search");
      this.salesBus.emit("clear-form");
      
    }

  }

  // Metodo para comprar
  purchase(totalPrice){

    // Cobrar
    const dialogRef = this.dialog.open(DialogSaleOverview, {
      width: '300px',
      autoFocus: true,
      data: {total: totalPrice}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){


        // TODO HANDLE SERVER ERRORS

        // SAVE SALE
        let cash = localStorage.getItem('cash');
        let user = localStorage.getItem('user_id');
        this.saleservice.createSale({cash:cash,user:user,sale:this.saleProducts}).subscribe( result => {
          if(!result.errors){
            this.snack.open("Venta Guardada","OK",  {duration: 1800})
            this.clear();
          }else{

            if(result.errors[0].hasOwnProperty("empty_sale_items")){
              this.snack.open(result.errors[0].empty_sale_items,"OK",  {duration: 1800})
            }else{
              this.snack.open("No pudo guardarse la venta","OK",  {duration: 1800})
            }
          }
        })

      }
    });

  }

  clear(){
    this.saleProducts = [];
    this.saleProductsCodes = [];
    this.listen.emit("refresh");
    this.salesBus.emit("focus-search");
    this.salesBus.emit("clear-form");
  }


  @ViewChild("tableContainer",{static: false}) tableContainer : ElementRef;

}