import { Product } from './ProductModel';

export class WarehouseEntry{

    static IN_ENTRY: string  = "ENTRADA"
    static OUT_ENTRY: string  = "SALIDA"

    constructor(
        public id?:number,
        public ProductId?: number,
        public tipo?: string,
        public cantidad?: number,
        public precio?: number,
        public createdAt?: number,
        public Product?: Product
        ) {
    }

}