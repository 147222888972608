import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/models/ProductModel';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { GastosService } from 'src/app/services/gastos.service';
import { Gastos } from 'src/app/models/GastosModel';

@Component({
  selector: 'app-gastos-add',
  templateUrl: './gastos-add.component.html',
  styleUrls: ['./gastos-add.component.styl']
})
export class GastosAddComponent implements OnInit {
  newGasto : Gastos;
  
  constructor(
    public snack: MatSnackBar,
    private router: Router,
    private gastosService: GastosService
    ) {  }

  ngOnInit() {
    this.newGasto = new Gastos();
  }

  createNewGasto(){
  
     this.gastosService.CreateGasto(this.newGasto).subscribe( response => {

      if(!response.errors){
        this.snack.open("Gasto agregado correctamente.","Ok",{duration: 2300})
        this.router.navigate(["backoffice/gastos"])
      }else{
        this.snack.open("Error "+response.errors[0],"Ok")
      }
     })

  }

}
